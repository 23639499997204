/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 25,
    height= 25,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg width="402" height="401" viewBox="0 0 402 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4029_13084)">
      <path d="M1 364C1 313.979 1 263.958 1.46866 213.469C64.1779 213 126.418 213 189 213C189 276.435 189 339.217 189 402C139.312 402 89.6249 402 39.3461 401.649C38.4015 400.949 38.1046 400.397 37.6866 400.281C20.5434 395.528 9.33582 384.532 3.38599 367.905C2.88274 366.499 1.8088 365.297 1 364Z" fill="#799D4B"/>
      <path d="M361 402C312.312 402 263.625 402 214.469 401.531C214 338.822 214 276.582 214 214C277.435 214 340.217 214 403 214C403 263.354 403 312.708 402.646 362.657C401.899 363.763 401.262 364.228 401.15 364.796C398.701 377.259 391.317 386.536 381.091 393.176C375.036 397.107 367.739 399.123 361 402Z" fill="#799D4B"/>
      <path d="M403 41C403 89.6876 403 138.375 402.531 187.531C339.489 188 276.915 188 214 188C214 125.232 214 63.1161 214 1C263.354 1 312.708 1 362.695 1.34863C378.301 5.07396 389.759 13.2285 396.919 26.9174C399.283 31.4356 400.992 36.2961 403 41Z" fill="#799D4B"/>
      <path d="M49.2506 136.75C26.472 114.572 13.5274 87.735 9.11283 56.8429C7.34804 44.4932 6.78988 31.953 6.10869 19.4763C5.86035 14.9276 6.84925 10.3112 7.33965 5.01313C15.8005 5.01313 24.4525 4.37589 32.9695 5.17627C43.3506 6.15183 53.7716 7.69629 63.9037 10.127C87.0795 15.6868 108.023 25.7367 126.269 41.5457C140.538 53.9094 151.51 68.5135 160.498 84.9224C171.426 104.872 178.396 126.159 181.908 148.513C183.891 161.134 184.692 173.941 185.975 186.669C186.103 187.945 185.88 189.256 185.792 191.14C178.168 190.396 170.708 190.412 163.606 188.826C146.941 185.103 130.2 181.393 113.947 176.237C93.8725 169.868 75.6474 159.552 59.445 145.927C56.0376 143.062 52.8107 139.981 49.2506 136.75Z" fill="#799D4B"/>
      </g>
      <defs>
      <clipPath id="clip0_4029_13084">
      <rect width="402" height="401" fill="white"/>
      </clipPath>
      </defs>
      </svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 25,
      height: 25,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 40,
      height: 47,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 40,
      height: 40,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
